import React from 'react'
import './Choose.css'

function Choose() {
  return (
    <div className='choose'>
        <h2>Why Choose Us</h2>

        <div className='choose-con'>
            <div className='choose-box'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692909/Group_1686552781_e6k9fk.png" alt="" />     
                <p>Cutting-Edge AI Technology</p>
                <span>We harness the power of OpenAI ChatGPT 3.5 Turbo and GPT-4 to ensure you receive the most advanced AI-driven responses.</span>
            </div>
            <div className='choose-box'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692910/Group_1686552782_wbxb06.png" alt="" />     
                <p>Seamless User Experience</p>
                <span>Our user-friendly interface makes it easy for anyone to dive into the world of AI creativity, regardless of technical expertise.</span>
            </div>
            <div className='choose-box'>
               <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692911/Group_1686552783_qfrpuf.png" alt="" />     
                <p>Endless Creativity</p>
                <span>From insightful conversations to mesmerizing art, our app lets your creativity flourish like never before.</span>
            </div>
        </div>
    </div>
  )
}

export default Choose