import React from 'react'
import {Link} from "react-router-dom"
import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
        <div>
             <div className='pre-footer'>
               <h2>Unlock the potential of AI generative innovation with Edge AI.</h2>
               <p>Join us on this exciting journey!</p>
               <div className='foot-download'>
                <Link className='download-box'>
                    <img className='app-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692918/uiw_android_i3b9ry.png" alt="" /> 
                    <button>Download Now</button>
                </Link>
                
                <Link className='download-box'>
                    <img className='app-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696733735/ic_outline-apple_gh1zb0.png" alt="" /> 
                    <button>Download Now</button>
                </Link>
               </div>
              </div>         
        </div>  

        <div className='footer-con'>
        <div className='brief'>
              <div className='footer-logo'>
                <p>Edge AI</p>
                <span>Ready to take the leap into the future? Get started today and 
                  unlock the limitless potential of AI creativity at your fingertips!
                </span>
              </div>

              <div className='contact-box'>
                <p>Contact Us</p>
                <Link className='support' to= {"mailto:Support@askedgeai.com?subject = Feedback&body = Message"}>Support@askedgeai.com </Link>

                <div className='social-links'>
                  <Link className='social-box' to= {"https://www.facebook.com/askedgeai"} >
                    <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692915/Group_20_yvq0ti.png" alt="" /> 
                  </Link>
                  <Link className='social-box' to= {"https://twitter.com/AskEdgeAi?t=2omPJgpq0kvScWB0UUFmJw&s=09"} >
                    <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692887/Group_21_gy6ubo.png" alt="" /> 
                  </Link>
                  <Link className='social-box' to= {"https://instagram.com/askedgeai?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"} >
                    <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692887/Group_22_uthuwc.png" alt="" /> 
                  </Link>
                  <Link className='social-box' to= {"http://tiktok.com/@askedgeai"} >
                    <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692887/Group_1686552795_wbpbwc.png" alt="" /> 
                  </Link>
                </div>
              </div>
           </div>


           <div className='copy'>
            <div>
              <p>©Edge AI 2023. All rights reserved</p>
            </div>


            <div className='legal'>
              <Link className='legal-link' to= {`./privacy`}>Privacy Policy </Link>
              <Link className='legal-link' to= {`./terms`}>Terms of Service </Link>
            </div>
           </div>

        </div>
    </div>
  )
}

export default Footer