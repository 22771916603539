import React from 'react'
import './Navbar.css'
import {Link} from "react-router-dom"
import { useState } from 'react'


const Navbar = () => {
    const [navOpen, setNavOpen] = useState(false)

    return (
      <div className="navbar">
          <div className='nav-con'>
            <div className='logo'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692937/icon_muzqtd.png" alt="" />
               <p>Edge AI</p>
            </div>

            <div className='navlinks'>
              <ul>
              <Link className='nav-link' to= {`./`}>Home </Link>
                <li className='nav-link'>Features</li>
                <li className='nav-link'>Pricing</li>
              </ul>
            </div>

            <div className='try-btn'>
              <button>Try for free</button>

              <div className='menu-toggle' onClick={() => setNavOpen(!navOpen)}>
                    <div className= {navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                        <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                        <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
                    </div>
                </div>
            </div> 

            
            <div className='nav-overlay' style={{
                top: navOpen ? "0" : "-180%",
                transitionDelay : navOpen ? "0s" : "0s"
            }}>

                <ul className='nav-links'>
                    <li className='nav-item'>
                      <Link to= {`./`} onClick={() => setNavOpen(!navOpen)} style={{
                           top: navOpen ? "0" : "150px",
                           transitionDelay : navOpen ? "0" : "0s"   
                      }} >  Home</Link>
                       <div className='nav-wrapper'></div>
                    </li>  

                    <li className='nav-item'>
                      <Link to= {`./privacy`} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Privacy Policy</Link>
                       <div className='nav-wrapper'></div>
                    </li>  

                    <li className='nav-item'>
                      <Link to= {`./terms`} onClick={() => setNavOpen(!navOpen)} style={{
                         top: navOpen ? "0" : "150px",
                         transitionDelay : navOpen ? "0s" : "0s" 
                      }}>Terms of Service</Link>
                       <div className='nav-wrapper'></div>
                    </li>  
           
                </ul>
            </div>
          </div>    
      </div>
    )
  }
export default Navbar

