import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import ScrollTop from './Components/Scrolltop'
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import Privacy from './Pages/Privacy/Privacy';
import Terms from './Pages/Terms/Terms';
import './App.css';

const Layout = () =>{
  return(
    <div className="main">
      <ScrollTop />
      <Navbar />
      <div className='topbar'></div>
      <Outlet/>
      <Footer />
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children:[
      {
        path:"/",
        element:<Home/>,
      },
      {
        path:"/privacy",
        element:<Privacy/>,
      },
      {
        path:"/terms",
        element:<Terms/>,
      },
    ]
  },

])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />    
    </div>
  );
}

export default App;
