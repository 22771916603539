import React from 'react'
import './Subscription.css'

function Subscription() {
  return (
    <div className='sub'>
        <h2>Our Subscriptions</h2>

        <div className='sub-con'>
            <div className='sub-img'>
                <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692916/Group_1686552655_l2ukec.png" alt="" />
                <div className='sub-text'>
                    <p>Easy Plan, <br></br> Easy Price.</p>
                    <span>Get started today and unlock the limitless potential of AI creativity at your fingertips!</span> 
                </div>     
            </div>
            <div className='sub-box'>
                <div className='plan-info'>
                    <p>Basic - Free Version</p>
                    <span>Essential features to get you started for free</span>
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Powered by GPT 3.5 turbo</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Powerful Machine Learning</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Limited access</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Ad- supported</p> 
                </div>

                <button>Try for free</button>
            </div>
            
            <div className='sub-box'>
                <div className='plan-info'>
                    <p>Pro - Premium Version</p>
                    <span>Essential features to get you started for free</span>
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Powerful Machine Learning</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Ad- free experience</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Access to GPT- 4</p> 
                </div>

                <div className='price-feature'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692914/Group_12_qp2tsb.png" alt="" />
                   <p>Unlimited access</p>  
                </div>

                <button>Purchase</button>
            </div>
            
        </div>
    </div>
  )
}

export default Subscription