import React from 'react'
import './Feature.css'

function Feature() {
  return (
    <div className='feat'>
        <h2>Our Features</h2>

        <div className='feat-con'>
            <div className='feat-left'>
                <div className='feat-box'>
                    <div><img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692955/icons8-chat-48_rk2cst.png" alt="" /> </div>
                    <p>AI Chatbox</p>
                    <span>Say hello to our AI Chatbox powered by OpenAI ChatGPT 3.5 Turbo and GPT-4. You can ask AI anything, and it will provide you with human-like answers.</span>
                </div>

                <div className='feat-box'>
                    <div><img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692957/icons8-robot-96_zlzcd2.png" alt="" /> </div>
                    <p>AI Friend Feature</p>
                    <span>Need a chat companion with a specific personality? Customize your AI friend with preferred personalities and get generative answers tailored to your liking.</span>
                </div>
            </div>

            <div className='feat-right'>
                <div className='art-text'>
                    <div><img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692956/icons8-gallery-100_d2hqga.png" alt="" /> </div>
                    <p>AI Art and Image Generator</p>
                    <span>Transform your words into stunning visuals! Whether it's turning text into art or converting one image into another. Our AI-driven image generator powered by Stable Diffusion technology will amaze you. Create unique, shareable content effortlessly.</span>
                </div>

                <div className='art-image'>
                  <div><img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692999/WhatsApp_Image_2023-10-05_at_08.52.19_b82ad2ea_tusv0q.jpg" alt="" /> </div>
                  <div><img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696693002/WhatsApp_Image_2023-10-05_at_08.52.20_36a12c19_tobylv.jpg" alt="" /> </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Feature