import React from 'react'
import './Home.css'
import {Link} from "react-router-dom"
import Choose from '../../Components/Choose/Choose'
import Work from '../../Components/Work/Work'
import Subscription from '../../Components/Subscription/Subscription'
import Feature from '../../Components/Feature/Feature'



function Home() {
  return (
    <div>
      <div className='hero'>
        <h2>Unleash the <strong>Future of Conversations</strong> and Artistry with GPT-4 
          AI Chat and Stable Diffusion Art Generator.
        </h2>
        <p>Edge AI brings you a revolutionary AI generative app that's 
          designed to elevate your digital experience.
        </p>

        <div className='download'>
          <Link className='download-box'>
              <p>Mobile and Tablet</p>
              <h3>Android</h3>
              <img className='app-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692918/uiw_android_i3b9ry.png" alt="" /> 
              <button>Download Now</button>
           </Link>
          
          <Link className='download-box'>
              <p>Mobile and Tablet</p>
              <h3>iOS</h3>
              <img className='app-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696733735/ic_outline-apple_gh1zb0.png" alt="" /> 
              <button>Download Now</button>
           </Link>
        </div>

        <img className='hero-img' src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696693671/852_1_xjdtae.jpg" alt="" />     
      </div> 

      <div className='welcome'>
        <div>
         <h2>Welcome to Edge AI - Unleash the Power of AI Creativity</h2>
        </div>

        <div>
         <p>Welcome to the future of AI interaction, creativity, and companionship!
           Edge AI is the ultimate AI generative app designed to elevate your digital experience. 
        </p></div>
      </div> 
       <Feature/>
      <Choose/>
      <Work />
      <Subscription />
 
    </div>
  )
}

export default Home
