import React from 'react'
import "./Privacy.css"

function Privacy() {

  return (
    <div className='privacy'>
      <h2>Privacy Policy</h2>   

      <div className='privacy-box'>
        <p>Last Updated: November, 1st 2023</p>
        <span>This Privacy Policy outlines how Silver Bull Technologies, the maker, 
             and owner of the Edge AI App, uses and safeguards your information when
             you use our application. We are committed to ensuring your privacy is 
             protected. If we request any information that can identify you while using our app,
             rest assured it will only be utilized in accordance with this privacy statement.
             Our policy may undergo updates, so we recommend periodically checking this page
             to stay informed and ensure your continued satisfaction.
        </span>
      </div>  

      <p className='effective'>This policy is effective from November, 1st 2023.</p>

      <div className='privacy-box'>
        <p>1. What Information We Collect</p>
        <span>We aim to collect only the minimum amount of data necessary to enhance your experience.
              We may collect the following information: <br></br>

              - Device information <br></br>
              - Email address <br></br>
              - First and last name 
        </span>
      </div>   

      <div className='privacy-box'>
        <p>2. What Information the Edge AI App Will Provide</p>
        <span>The Edge AI App, developed by Silver Bull Technologies, is a service 
              designed to protect your conversations from well-known messenger apps
              and provide backups. We do not guarantee a 100% success rate in all cases.
              By downloading and using the app, you acknowledge that we cannot ensure exact 100% results.
        </span>
      </div>  

      <div className='privacy-box'>
        <p>3. How We Use the Gathered Information</p>
        <span>We gather this information to better understand your needs and provide 
              improved services, specifically for the following reasons: <br></br>

              - Enhancing your app experience <br></br>
              - Using the information to improve our products and services<br></br>
              - Periodically sending promotional emails about new products, special offers, or other relevant information to the email address you've provided
        </span>
      </div>   

      <div className='privacy-box'>
        <p>4. Security Measures by Silver Bull Technologies</p>
        <span>We are committed to safeguarding your information's security. We employ appropriate physical, electronic, and managerial procedures to prevent unauthorized access or disclosure of the data we collect online.
        </span>
      </div>  

      <div className='privacy-box'>
        <p>5. Our Use of Cookies</p>
        <span>A cookie is a small file that requests your permission to be placed on your computer's hard drive. Once you grant permission, the file is added, and the cookie helps us analyze web traffic or notify you when you visit a specific site. Cookies enable web applications to tailor their operations to your preferences by gathering and remembering information about your likes and dislikes. We use traffic log cookies to identify which pages are being accessed. This helps us analyze webpage traffic and improve our website to better suit customer needs. We only use this information for statistical analysis, and then the data is removed from the system. Overall, cookies help us provide you with an improved website by allowing us to monitor which pages you find useful and which you do not. A cookie does not provide access to your computer or any information about you, other than the data you choose to share with us. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, this may prevent you from fully utilizing the website.
        </span>
      </div>  

      <div className='privacy-box'>
        <p>6.Links to Other Websites</p>
        <span>
         Our app may include links to other content of interest. Please note that once you leave our app using these links, we have no control over the protection and privacy of any information you provide on those external sites. Our privacy statement does not govern these external websites. We advise exercising caution and reviewing the privacy statement applicable to the external website in question.
        </span>
      </div>  

      <div className='privacy-box'>
        <p>7. Controlling Your Personal Information</p>
        <span>We will not sell, distribute, or lease your personal information to third parties unless we have your explicit permission or are legally required to do so. We may use your personal information to send you promotional information about our own products and services that we believe you may find interesting. If you believe that any information we hold about you is inaccurate or incomplete, please contact us promptly. We will promptly rectify any inaccuracies found. For inquiries, please contact support at support@askedgeai.com.
        </span>
      </div>  

    </div>
  )
}

export default Privacy