import React from 'react'
import "./Terms.css"

function Terms() {
  return (
    <div className='privacy'>
      <h2>Terms of Use</h2>   

      <div className='privacy-box'>
        <p>Effective Date: November 1st, 2023</p>
        <span>Thank you for choosing Silver Bull Technologies Ltd. and using our Edge AI app. We are dedicated to continuous improvement and the creation of innovative products to provide the best possible value to our users. These Terms of Service apply to all products developed by Silver Bull Technologies Ltd. Please read these terms carefully before using our applications.
          <br></br>
          <br></br>
          By using Silver Bull Technologies Ltd. products, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our apps. If you are using our products on behalf of an organization, such as your employer, you are agreeing to these Terms on behalf of that organization and confirming that you have the authority to bind that organization to these Terms. In such cases, "you" and "your" will refer to that organization.
        </span>
      </div>  

      <div className='privacy-box'>
        <p>1. Auto Renewing Subscriptions:</p>
        <span>
              - Subscribed users have unlimited access to the app's translation services, including speech-to-text recognition, translation, and text-to-speech. <br></br>
              - Unsubscribed users can only access these services with a limited daily quota. <br></br>
              - Payment will be charged to your iTunes or Google pay Account upon confirmation of purchase. <br></br>
              - Subscriptions will automatically renew unless auto-renew is turned off at least 24 hours before the current period ends. <br></br>
              - Your account will be charged for renewal within 24 hours prior to the end of the current period, and the renewal cost will be identified. <br></br>
              - Users can manage subscriptions and turn off auto-renewal by visiting the user's Account Settings after purchase. <br></br>
              - Any unused portion of a free trial period, if offered, will be forfeited upon the purchase of a subscription to that publication, where applicable.           
        </span>
      </div> 

      
      <div className='privacy-box'>
        <p>2. General Prohibitions</p>
        <span>By using our apps, you agree not to do, or attempt to do, any of the following: <br></br>

              - Probe, scan, or test the vulnerability of any Silver Bull Technologies system or network, or breach any security or authentication measures. <br></br>
              - Access, tamper with, or use non-public areas of Silver Bull Technologies, Silver Bull Technologies' computer systems, or the technical delivery systems of Silver Bull Technologies' providers.<br></br>
              -  Decipher, decompile, disassemble, or reverse engineer any of the software used to provide Silver Bull Technologies.<br></br>
              -  Interfere with, or attempt to interfere with, the access of any user, host, or network, including sending a virus, overloading, flooding, spamming, or mail-bombing Silver Bull Technologies.<br></br>
              - Access or search Silver Bull Technologies or download any intellectual property from Silver Bull Technologies using any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools, or the like) other than our publicly supported interfaces.<br></br>
              - Plant malware or use Silver Bull Technologies to distribute malware.<br></br>
              - Send any unsolicited communications, promotions, advertisements, or spam.<br></br>
              - Send altered, deceptive, or false source-identifying information, including "spoofing" or "phishing."<br></br>
              - Post or transmit anything that is fraudulent, misleading, or infringes on the rights of others.<br></br>
              -  Impersonate or misrepresent your affiliation with any person or entity.<br></br>
              - Violate the privacy of others.<br></br>
              - Violate any applicable law or regulation, or encourage or enable any other individual to do any of the above.<br></br> <br></br>

              While we are not obligated to monitor access to or use of Silver Bull Technologies or your content, we reserve the right to do so to operate Silver Bull Technologies, ensure compliance with these Terms, or meet legal requirements. We also reserve the right, though not the obligation, to remove or disable access to any of your content at any time and without notice, including if we, at our sole discretion, consider any of your content to be objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects Silver Bull Technologies. We may also collaborate with law enforcement authorities to prosecute users who violate the law.
              
        </span>
      </div>    
      
    </div>
  )
}

export default Terms