import React from 'react'
import './Work.css'

function Work() {
  return (
    <div className='work'>
        <h2>How it works</h2>

        <div className='work-con'>
            <div className='work-left'>
                <div className='work-box'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692911/Frame_32_jczgvx.png" alt="" /> 
                    <div>
                     <p>Sign Up</p>
                     <span>Create your account and tell us a bit about yourself. This helps us personalize your AI experience.</span>
                    </div>  
                </div>            
                <div className='work-box'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692911/Frame_32_jczgvx.png" alt="" /> 
                    <div>
                     <p>Choose Your Mode</p>
                     <span>Select from AI Chatbox, AI Art Generator, or AI Friend. You can switch between them anytime.</span>
                    </div>  
                </div>            
                <div className='work-box'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692911/Frame_32_jczgvx.png" alt="" /> 
                    <div>
                     <p>Start Chatting or Creating</p>
                     <span>Engage in conversations, describe your art ideas, or set up your AI friend's personality.</span>
                    </div>  
                </div>            
                <div className='work-box'>
                   <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696692911/Frame_32_jczgvx.png" alt="" /> 
                    <div>
                     <p>Enjoy the Magic</p>
                     <span>Watch as our advanced AI algorithms work their magic to provide you with outstanding results.</span>
                    </div>  
                </div>            
            </div>

            <div className='work-right'>
              <img src="https://res.cloudinary.com/dxnukbo0u/image/upload/v1696693671/3d-rendering-biorobots-concept_1_mcj1ab.jpg" alt="" />      
            </div>
        </div>
    </div>
  )
}

export default Work